import { render, staticRenderFns } from "./barCode.vue?vue&type=template&id=44c6b086&scoped=true&"
import script from "./barCode.vue?vue&type=script&lang=js&"
export * from "./barCode.vue?vue&type=script&lang=js&"
import style0 from "./barCode.vue?vue&type=style&index=0&id=44c6b086&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c6b086",
  null
  
)

export default component.exports