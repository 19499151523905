<template>
  <!-- 校园筛查 筛查计划管理 打印二维码 -->
  <div class="page">
    <!-- <title-text class="" :page-size="pageSize" :return-button="true" :Button="true" @printClick="print">
    </title-text> -->
    <div class="tabs-section">
      <el-tabs
        v-if="isSchoolId === 1"
        v-model="activeName"
        @tab-click="handleClick"
      >
        <el-tab-pane label="我创建的筛查计划" name="0"></el-tab-pane>
        <el-tab-pane label="学校上报的筛查计划" name="1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="header-container">
      <div class="header-section">
        <div class="header-input" v-if="activeName == 1">
          <el-select
            v-model="selectSchool"
            placeholder="请选择学校"
            size="mini"
            @change="getScreeningSelectS"
          >
            <el-option
              v-for="item in schoolList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="button-section">
          <el-button size="mini" @click="print"> 打印 </el-button>
          <el-button size="mini" @click="goTo('/detection/screeningListQuery')">
            返回
          </el-button>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="header-switc">
        <div
          class="header-switc-button header-switc-button-left"
          :class="switcClass == 0 ? 'active' : ''"
          @click="handleSwitc(0)"
        >
          条形码
        </div>
        <div
          class="header-switc-button header-switc-button-right"
          :class="switcClass == 1 ? 'active' : ''"
          @click="handleSwitc(1)"
        >
          二维码
        </div>
      </div>
      <div class="header-input">
        <el-select
          v-model="screeningListId"
          size="mini"
          filterable
          placeholder="请选择筛查计划"
          @change="getSchool"
        >
          <el-option
            v-for="(item, index) in screeningOptions"
            :key="item.id"
            :label="item.screeningName"
            :value="item.id"
            @click.native="changeScreening(index)"
          ></el-option>
        </el-select>
        <el-select
          v-if="activeName == 0"
          v-model="schoolId"
          size="mini"
          filterable
          placeholder="请选择学校"
          @change="getGradeSelect"
        >
          <el-option
            v-for="item in schoolOptions"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          ></el-option>
        </el-select>
        <el-select
          v-model="gradeId"
          clearable
          size="mini"
          placeholder="请选择年级"
          @change="getClassSelect"
        >
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="classId"
          clearable
          size="mini"
          placeholder="请选择班级"
          @change="search"
        >
          <el-option
            v-for="item in classOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-input
          maxlength="11"
          placeholder="请输入学生姓名搜索"
          size="mini"
          class="search"
          v-model="searchValue"
          style="width: 150px; margin-right: 10px"
        >
        </el-input>
        <el-button type="primary" size="small" @click="search()"
          >提交</el-button
        >
        <!--        <el-button size="small" @click="resetForm()">重置</el-button>-->
      </div>
      <div class="row-container">
        <el-row :gutter="20" id="printMe">
          <div
            v-for="(item, index) in tablePageData"
            :key="index"
            class="el-col"
            style="padding-left: 6px; padding-right: 6px"
          >
            <div
              class="box"
              :class="barCode ? 'Bar' : 'QR'"
              :style="
                barCode
                  ? 'width: 254px;height:200px; padding-bottom: 10px;'
                  : 'width: 188px;height: 270px;padding: 10px;'
              "
            >
              <svg
                :id="'barcode' + index"
                :width="width + 'px'"
                v-show="barCode"
              ></svg>
              <div :id="'qrcode' + index" v-show="!barCode"></div>
              <div class="textCont">
                <p>{{ item.gradeName }}</p>
                <p>{{ item.className }}</p>
                <p>{{ item.nickName }} {{ item.sex }}</p>
                <p>{{ item.screeningName }}</p>
              </div>
            </div>
          </div>
        </el-row>
        <div class="table-page" style="flex: 1">
            <!-- :pager-count="pagerCount" -->
          <pagination
            :current-page="startPage"
            :total="total"
            :page-sizes="[15, 35, 70, 120]"
            :page-size="pageSize"
            @currentChange="handleCurrentChange"
            @sizeChange="handleSizeChange"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import printJS from 'print-js'
import screeningService from '@/globals/service/detection/screening.js'
import selectService from '@/globals/service/detection/select.js'
import storage from '@/globals/storage/index.js'
import organizationService from '@/globals/service/management/organization.js'

var JsBarcode = require('jsbarcode')

export default {
  data () {
    return {
      selectSchool: null,
      schoolList: [],
      // barStyle:"width: 20%;min-width:266px;",
      // qrStyle:"width: 16%;min-width:200px;",
      activeName: 0,
      screeningListId: '',
      screeningOptions: [],
      schoolId: null,
      gradeId: null,
      classId: null,
      schooldis: false,
      gradedis: false,
      classdis: false,
      school: '',
      nickName: '',
      newGradeId: null,
      schoolClassId: null,
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      searchValue: '',
      total: 0,
      startPage: 1, // 当前页码
      pagerCount: 1,
      pageSize: 70,
      startPageScreening: 1,
      pageSizeScreening: 100,
      startPageSchool: 1,
      pageSizeSchool: 100,
      tableData: [],
      tablePageData: [],
      switcClass: 1,
      qrcodeImg: '',
      barCode: false,
      width: 240,
      isPrint: false
    }
  },
  created () {
    const schoolId = storage.get('schoolId')
    this.isSchoolId = schoolId ? 0 : 1
  },
  mounted () {
    // this.getListData()
    // const schoolId = storage.get('schoolId')
    // if (schoolId) {
    //   // this.searchScreening('')
    //   return
    // }、
    this.getScreeningSelect()
    this.getSchoolList()
  },
  methods: {
    resetForm () {
      this.screeningListId = ''
      this.schoolId = storage.get('schoolId')
      this.classId = ''
      this.gradeId = ''
      this.searchValue = ''
      this.getListData()
    },
    getScreeningSelectS () {
      this.schoolId = null
      this.schoolOptions = []
      this.screeningListId = null
      this.getScreeningSelect()
    },
    getSchoolList () {
      let data
      selectService.school({ startPage: 1, pageSize: 1000 }).then((res) => {
        // const schoolId = storage.get('schoolId')
        // if (schoolId) {
        //   data = res.data.schoolList.filter(item => item.id === schoolId)
        // } else {
        //   data = res.data.schoolList
        // }
        data = res.data.schoolList
        data = data.map((item) => {
          return {
            value: item.id,
            label: item.schoolName
          }
        })
        this.schoolList = data
        if (this.schoolList[0]) {
          this.selectSchool = this.schoolList[0].value
          // this.getListData()
        }
      })
    },
    getSchool () {
      if (!this.screeningListId) {
        this.schoolOptions = []
        this.gradeOptions = []
        this.classOptions = []
        this.schoolId = null
        this.gradeId = null
        this.classId = null
        this.search()
        return
      }
      const schoolId = storage.get('schoolId')
      organizationService
        .getSchoolAndUsers({ id: this.screeningListId })
        .then((res) => {
          const data = []
          res.data.screeningVoList.forEach((item) => {
            if (!data.some((items) => items.schoolId === item.schoolId)) {
              if (Number(schoolId)) {
                if (Number(schoolId) === item.schoolId) {
                  data.push({
                    schoolId: Number(item.schoolId),
                    schoolName: item.schoolName
                  })
                }
              } else {
                data.push({
                  schoolId: Number(item.schoolId),
                  schoolName: item.schoolName
                })
              }
              // data.push({
              //   schoolId: item.schoolId,
              //   schoolName: item.schoolName
              // })
            }
          })
          this.schoolOptions = data
          if (this.schoolOptions[0]) {
            this.schoolId = Number(this.schoolOptions[0].schoolId)
            this.getGradeSelect()
          }
        })
    },
    handleClick () {
      this.screeningListId = null
      this.tablePageData = []
      this.schoolId = null
      this.gradeId = null
      this.classId = null
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      this.searchValue = ''
      this.getScreeningSelect()
    },
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    print () {
      // this.barStyle = '';
      // this.qrStyle = '';
      this.$nextTick(() => {
        printJS({
          printable: 'printMe',
          type: 'html',
          targetStyles: ['*'],
          style: '#printMe {width: 80px;}',
          ignoreElements: ['no-print', 'bc', 'gb'],
          onPrintDialogClose: function () {
            // this.barStyle = 'width: 25%;min-width:266px;';
            // this.qrStyle = 'width: 16%;min-width:200px;';
          }
        })
      })
    },
    getListData () {
      const jsonData = {
        id: this.screeningListId,
        startPage: this.startPage,
        pageSize: this.pageSize,
        gradeId: this.newGradeId,
        schoolId: this.school,
        classId: this.schoolClassId,
        name: this.nickName
      }
      screeningService
        .qrcode(jsonData)
        .then((res) => {
          // console.log(res)
          if (res.data.totalPage) {
            // console.log('1', res.data.totalPage)
            this.total = res.data.totalPage
            this.tablePageData = res.data.list || []
            this.$nextTick(() => {
              if (this.switcClass) {
                this.creatQrCode()
              } else {
                this.JsBarcode()
              }
            })
          } else {
            this.total = 0
            this.tablePageData = []
          }
        })
        .catch(() => {
          for (let i = 0; i < 50; i++) {
            this.tablePageData.push({
              childrenId: '0',
              screeningId: '0'
            })
          }
          this.$nextTick(() => {
            if (this.switcClass) {
              this.creatQrCode()
            } else {
              this.JsBarcode()
            }
          })
        })
    },
    getScreeningSelect () {
      const data = {
        type: this.activeName,
        startPage: this.startPageScreening,
        pageSize: this.pageSizeScreening
      }
      if (this.selectSchool) data.schoolId = this.selectSchool
      selectService.screening(data).then((res) => {
        // let screeningOptions = res.data.list || []
        const screeningOptions = []
        // const schoolId = storage.get('schoolId')
        res.data.list.forEach((item) => {
          const zhi = screeningOptions.some((items) => item.id === items.id)
          if (!zhi) {
            screeningOptions.push(item)
          }
        })
        // if (schoolId) {
        //   screeningOptions = screeningOptions.filter(item => {
        //     return Number(item.schoolId) === Number(schoolId)
        //   })
        // }
        // if (screeningOptions.length >= 1 && this.$route.query.id) {
        //   this.screeningListId = Number(this.$route.query.id)
        //   this.screeningOptions = screeningOptions
        //   this.getSchool()
        //   this.getListData()
        //   return
        // }
        // if (!this.$route.query.id && screeningOptions.length > 0) {
        //   const row = screeningOptions[0]
        //   this.screeningListId = row.id
        //   if (row.schoolId) {
        //     this.schoolOptions = [{
        //       value: row.schoolId,
        //       label: row.schoolName
        //     }]
        //     this.schooldis = true
        //     this.school = row.schoolId
        //     this.schoolId = row.schoolId
        //     this.getGradeSelect(row)
        //   }
        //   if (row.gradeId) {
        //     this.gradeId = row.gradeId
        //     this.newGradeId = row.gradeId
        //     this.gradedis = true
        //   }
        //   if (row.classId) {
        //     this.classId = row.classId
        //     this.schoolClassId = row.classId
        //     this.classdis = true
        //   }
        //   this.getListData()
        // } else {
        //   this.$alert('没有可选的筛查计划，请添加', '提示', {
        //     type: 'info',
        //     center: true,
        //     confirmButtonText: '确定',
        //     callback: action => {
        //       this.$router.push({ path: '/detection/screeningPlanAdd' })
        //     }
        //   })
        // }
        this.screeningOptions = screeningOptions
        if (this.screeningOptions[0]) {
          this.screeningListId = this.screeningOptions[0].id
          this.getSchool()
        }
      })
    },
    // searchScreening (row) {
    //   screeningService.search({ name: row }).then(res => {
    //     this.screeningOptions = res.data || []
    //   })
    // },
    changeScreening (index) {
      this.startPage = 1
      this.screeningListId = null
      this.schoolId = null
      this.gradeId = null
      this.classId = null
      // this.schooldis = false
      // this.gradedis = false
      // this.classdis = false
      this.school = ''
      this.newGradeId = null
      this.schoolClassId = null
      this.nickName = ''
      const row = this.screeningOptions[index] || null
      this.screeningListId = row.id
      // if (row.schoolId) {
      //   this.schoolOptions = [{
      //     value: row.schoolId,
      //     label: row.schoolName
      //   }]
      //   this.schoolId = row.schoolId
      //   this.school = row.schoolId
      //   this.schooldis = true
      //   this.getGradeSelect(row)
      // }
      // if (row.gradeId) {
      //   this.gradeId = row.gradeId
      //   this.newGradeId = row.gradeId
      //   this.gradedis = true
      // }
      // if (row.classId) {
      //   this.classId = row.classId
      //   this.schoolClassId = row.classId
      //   this.classdis = true
      // }
      // this.searchScreening('')
      this.getListData()
    },
    getGradeSelect (row) {
      if (!this.schoolId) {
        this.gradeOptions = []
        this.classOptions = []
        this.gradeId = null
        this.classId = null
        this.search()
        return false
      }
      this.gradeOptions = []
      this.classOptions = []
      if (row && row.gradeId) {
        this.gradeId = row.gradeId
      } else {
        this.gradeId = null
        this.classId = null
      }
      selectService.grade({ schoolId: this.schoolId + '' }).then((res) => {
        const gradeList = res.data
        const gradeOptions = []
        gradeList.forEach((item) => {
          gradeOptions.push({
            value: item.id,
            label: item.gradeName
          })
        })
        this.gradeOptions = gradeOptions
        if (gradeOptions.length > 0) {
          this.getClassSelect(row)
        }
        this.search()
      })
    },
    getClassSelect (row) {
      this.classOptions = []
      if (!this.gradeId) {
        this.classOptions = []
        this.classId = null
        this.search()
        return false
      }
      if (row && row.classId) {
        this.classId = row.classId
      } else {
        this.classId = null
      }
      selectService
        .class({ gradeId: this.gradeId + '' })
        .then((res) => {
          const classList = res.data
          const classOptions = []
          classList.forEach((item) => {
            classOptions.push({
              value: item.id,
              label: item.className
            })
          })
          this.classOptions = classOptions
          this.search()
        })
        .catch((err) => {
          if (err.errMsg) {
            this.$message.error(err.errMsg)
          } else {
            this.$notify.error({ title: '错误', message: '服务异常' })
          }
        })
    },
    creatQrCode () {
      for (let i = 0; i < this.tablePageData.length; i++) {
        const str =
          this.tablePageData[i].childrenId +
          '#' +
          this.tablePageData[i].screeningId
        document.getElementById('qrcode' + i).innerHTML = ''
        /* eslint-disable no-new */
        new QRCode('qrcode' + i, {
          width: 160,
          height: 160,
          text: str
        })
      }
    },
    JsBarcode () {
      for (let i = 0; i < this.tablePageData.length; i++) {
        const str =
          this.tablePageData[i].childrenId +
          '#' +
          this.tablePageData[i].screeningId
        document.getElementById('qrcode' + i).innerHTML = ''
        JsBarcode('#barcode' + i, str, {
          width: 1.8,
          displayValue: false
        })
      }
      this.width = 240
    },
    search () {
      this.startPage = 1
      this.school = this.schoolId
      this.newGradeId = this.gradeId
      this.schoolClassId = this.classId
      this.nickName = this.searchValue
      this.getListData()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.startPage = 1
      this.getListData()
    },
    handleCurrentChange (val) {
      this.startPage = val
      this.getListData()
    },
    handleSwitc (type) {
      if (this.switcClass === type) {
        return false
      } else {
        this.switcClass = type
        this.barCode = !this.barCode
        if (this.barCode) {
          this.JsBarcode()
        } else {
          this.creatQrCode()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  padding: 10px 20px;
  min-height: 100%;
  background-color: #fff;
}
.header-container {
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .button-section {
      display: flex;
      align-items: center;
    }
  }
}
.page .header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0 16px;
}
.el-row {
  margin: 20px 0;
  width: 100%;
}
.el-row:last-child {
  margin-bottom: 0;
}
.qrcode {
  display: inline-block;
}
.qrcode img {
  background-color: #fff; //设置白色背景色
  padding: 6px; // 利用padding的特性，挤出白边
  box-sizing: border-box;
}
.el-col {
  page-break-after: always;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 6px;
  flex: 1;
  box-sizing: border-box;
}

.el-col .box {
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
}
.el-col .box .textCont {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  margin-top: 10px;
  box-sizing: border-box;
}
.el-col .Bar .textCont {
  margin-top: 4px;
}

.el-col .box .textCont p {
  margin: 0;
  width: 100%;
  display: block;
}

.el-col .box img {
  width: 300px;
  height: 76px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.page .header .el-input,
.page .header .el-button {
  margin-top: 5px;
  margin-bottom: 5px;
}
.el-select {
  max-width: 160px;
  margin-right: 10px;
}
.header-search .search >>> .el-input__inner {
  width: 150px;
  border: 1px solid #333;
  border-radius: 18px;
  margin-right: 16px;
}
.header-button {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.header-switc {
  display: flex;
  align-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-size: 14px;
  color: #999999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.header-switc-button {
  padding: 10px 12px;
  border: 1px solid #409eff;
  background-color: #ffffff;
}
.header-switc-button.header-switc-button-left {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.header-switc-button.header-switc-button-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.header-switc-button.active {
  background-color: #409eff;
  color: #fff;
}
.header-switc-button:hover {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #333;
}
.row-container {
  width: 100%;
}
</style>
